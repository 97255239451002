import { Module } from "vuex";
import process from "../helpers/process";

export const codStore: Module<any, any> = {
  namespaced: true,
  getters: {
    storeId: (state) => state.storeId,
  },
  mutations: {
    setStoreId(state, storeId) {
      state.storeId = storeId;
    },
  },
  actions: {
    init({ commit, rootState }) {
      commit("setStoreId", rootState.storeId);
    },
    async launch({ getters }, { order, config }) {
      let orderStatus = {
        status: "initialized",
        order: null,
        error: null,
      };
      const sfId = getters.storeId;
      try {
        orderStatus = await process(sfId, config.id, order);
        //dispatch("order/onOrderCreate", order, { root: true });
      } catch (error) {
        orderStatus.status = "error";
        orderStatus.error =
          "Could not place your order. Please try again after some time.";
        console.error(error);
      }
      return orderStatus;
    },
  },
};
