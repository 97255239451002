// Register all payment modules - primarily stores and event listeners
// Pre registration hook
import { StorefrontModule } from "@lib/appRegister";
import { codStore } from "./store";

export const CodModule: StorefrontModule = function ({
  app,
  store,
  router,
  moduleConfig,
  appConfig,
}) {
  store.registerModule("payment/pof", codStore);
  store.dispatch("payment/pof/init");
};
