import axios from "axios";
import config from "@config/config";

const process = async (
  sfId: string,
  providerId: string,
  order: any
): Promise<any> => {
  const processEndpoint = config.serverlessUrl + "payment/pof/process";
  const response = await axios.post(processEndpoint, {
    sfId,
    providerId,
    order,
  });
  return response.data;
};

export default process;
